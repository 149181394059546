/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  }
];
